import('../vue-shared').then(({default: vue}) => {
  $(() => {
    const mountPoint = document.getElementById('vue-documentation');
    if (!mountPoint) {
      return;
    }

    const initData = mountPoint.getAttribute('data-init-data');
    vue.prototype.$documentationInitData = JSON.parse(initData ? initData : '{}');

    import('./Documentation.vue').then(({default: component}) => {
      new vue({
        render: (h) => h(component),
        el: mountPoint,
      }).$mount();
    });
  });
});
